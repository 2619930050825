<template>
    <div
        v-if="isShowBtn"
        class="scroll-btn-wrapper"
        :class="isInModal ? 'modal-scroll' : 'body-scroll'"
    >
        <div
            class="go-top-btn icon"
            :class="{ disabled: isDisabledTop }"
            @click="goToPosition('top')"
        />
        <div
            class="go-bottom-btn icon"
            :class="{ disabled: isDisabledBottom }"
            @click="goToPosition('bottom')"
        />
    </div>
</template>

<script>
import smoothscroll from 'smoothscroll-polyfill'
import ElementResizeDetectorMaker from 'element-resize-detector'
export default {
    name: 'GoTopButton',
    emits: [],
    props: {
        isInModal: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        goToPosition: function (pos) {
            this.$nextTick(() => {
                smoothscroll.polyfill()
                const html = document.querySelector('html')
                const scrollHeight = html.scrollHeight

                if (pos === 'top')
                    html.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    })
                else if (pos === 'bottom')
                    html.scrollTo({
                        top: scrollHeight,
                        behavior: 'smooth'
                    })
            })
        },
        setHasScrollBar: function () {
            const html = document.querySelector('html')
            let scrollHeight,
                clientHeight = 0
            this.$nextTick(() => {
                scrollHeight = html.scrollHeight
                clientHeight = html.clientHeight
                this.isShowBtn = scrollHeight > clientHeight
            })
        },
        setDisabledBtn: function () {
            this.$nextTick(() => {
                const html = document.querySelector('html')
                let scrollTop = html.scrollTop ?? 0
                let scrollHeight = html.scrollHeight ?? 0
                let clientHeight = html.clientHeight ?? 0
                if (scrollTop === 0) this.isDisabledTop = true
                else if (scrollTop + clientHeight >= scrollHeight)
                    this.isDisabledBottom = true
                else {
                    this.isDisabledTop = false
                    this.isDisabledBottom = false
                }
            })
        }
    },
    computed: {},
    data() {
        return {
            isShowBtn: false,
            isDisabledTop: false,
            isDisabledBottom: false
        }
    },
    mounted() {
        this.$nextTick(() => {
            const erd = ElementResizeDetectorMaker()
            erd.listenTo(document.querySelector('.main-content'), () => {
                this.setHasScrollBar()
                this.setDisabledBtn()
            })
            this.setHasScrollBar
            window.addEventListener('scroll', this.setDisabledBtn, true)
        })
    },
    unmounted() {
        const erd = ElementResizeDetectorMaker()
        erd.removeListener(document.querySelector('.main-content'))
        window.removeEventListener('scroll', this.setDisabledBtn, true)
    }
}
</script>

<style scoped lang="scss">
.scroll-btn-wrapper {
    &.body-scroll {
        display: none;
        @media screen and (max-width: 576px) {
            display: block;
            width: fit-content;
            height: fit-content;
            position: fixed;
            bottom: 110px;
            right: 8px;
            z-index: 1;
            .icon {
                cursor: pointer;
                width: 30px;
                height: 30px;
                margin: 10px 0;
            }
        }
    }
    &.modal-scroll {
        display: block;
        width: fit-content;
        height: fit-content;
        position: absolute;
        bottom: 20%;
        right: 8px;
        z-index: 1;
        .icon {
            cursor: pointer;
            width: 30px;
            height: 30px;
            margin: 10px 0;
        }
        @media screen and (max-width: 576px) {
        }
    }
}
</style>
